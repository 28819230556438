<template>
  <div>
    <v-container fluid>
      <v-widget title="Lista Manifestos">
        <template v-slot:toolbar>
          <v-container fluid>
            <v-row dense>
              <v-col cols="10" sm="5">
                <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" @click:clear="limparBusca" @keyup.enter="filtrarManifestos($event)" max-width="300px" prepend-inner-icon="search" label="Busca"></v-text-field>
              </v-col>
              <v-col cols="2" sm="7" class="text-right">
                <v-tooltip bottom v-if="usuario.nivelAcesso !== 'ANRON'">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" fab small color="primary" class="mr-3 elevation-3" :disabled="certificadoVencido" @click="consultarStatusServicoMDFe()" :loading="carregandoStatus">
                      <v-icon dark v-if="statusSefaz">mdi-access-point</v-icon>
                      <v-icon dark v-else color="secondary darken-3">mdi-access-point-off</v-icon>
                    </v-btn>
                  </template>
                  <span>Status Serviço</span>
                </v-tooltip>
                <v-btn v-if="!isSm" depressed color="primary" @click="novoManifesto"> NOVO </v-btn>
                <v-btn v-else depressed fab small color="primary" @click="novo">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:widget-content>
          <v-data-table item-key="_id" :options.sync="options" :server-items-length="total" :headers="headers" :items="manifestos" :loading="carregando" mobile-breakpoint="0">
            <template v-slot:item="props">
              <tr @click="props.item.status === constantes.STATUS_CADASTRADA || props.item.status === constantes.STATUS_PROCESSAMENTO ? '' : props.expand(!props.isExpanded)" style="cursor: pointer">
                <td>{{ props.item.numero | formatarPadZero(6) }}</td>
                <td>{{ props.item.serie | formatarPadZero(3) }}</td>
                <td>
                  <v-chip pill outlined
                    ><v-avatar left :color="definirCor(props.item.status)">{{ definirLetra(props.item.status) }}</v-avatar
                    >{{ props.item.status }}</v-chip
                  >
                </td>
                <td>{{ props.item.data_emissao | formatarData }}</td>
                <td>{{ props.item.veiculo.placa | formatarPlaca }}</td>
                <td>{{ props.item.veiculo.uf }}</td>
                <td>{{ props.item.valor_carga | formatarMoeda }}</td>
                <td class="text-center">
                  <v-menu top right transition="slide-x-transition">
                    <template v-slot:activator="{ on }">
                      <v-icon @click.stop="" v-on="on" slot="activator">more_vert</v-icon>
                    </template>
                    <v-list dense>
                      <v-list-item
                        :key="props.item._id + 20"
                        @click="editarManifesto(props.item)"
                        v-show="props.item.status !== constantes.STATUS_AUTORIZADA && props.item.status !== constantes.STATUS_CANCELADA && props.item.status !== constantes.STATUS_ENCERRADO"
                      >
                        <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                        <v-list-item-title>Editar Manifesto</v-list-item-title>
                      </v-list-item>
                      <v-list-item :key="props.item._id + 10" @click="reprocessar({ id: props.item._id })" v-show="props.item.status === constantes.STATUS_CADASTRADA" :disabled="certificadoVencido">
                        <v-list-item-icon><v-icon>check_circle</v-icon></v-list-item-icon>
                        <v-list-item-title>Autorizar Manifesto</v-list-item-title>
                      </v-list-item>
                      <v-list-item :key="props.item._id + 5" @click="reprocessar({ id: props.item._id })" v-show="props.item.status === constantes.STATUS_ERRO" :disabled="certificadoVencido">
                        <v-list-item-icon><v-icon>replay</v-icon></v-list-item-icon>
                        <v-list-item-title>Reprocessar Manifesto</v-list-item-title>
                      </v-list-item>
                      <v-list-item :key="props.item._id" @click="reprocessar({ id: props.item._id })" v-show="props.item.status === constantes.STATUS_PROCESSAMENTO" :disabled="certificadoVencido">
                        <v-list-item-icon><v-icon>replay</v-icon></v-list-item-icon>
                        <v-list-item-title>Consultar Processamento</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :key="props.item._id + 15"
                        @click="visualizar(props.item)"
                        v-show="props.item.status === constantes.STATUS_AUTORIZADA || props.item.status === constantes.STATUS_ENCERRADO || props.item.status === constantes.STATUS_CANCELADA"
                      >
                        <v-list-item-icon><v-icon>mdi-eye</v-icon></v-list-item-icon>
                        <v-list-item-title>Visualizar Manifesto</v-list-item-title>
                      </v-list-item>
                      <v-list-item :key="props.item._id + 40" @click="cancelarManifesto(props.item)" v-show="props.item.status === constantes.STATUS_AUTORIZADA">
                        <v-list-item-icon><v-icon>cancel</v-icon></v-list-item-icon>
                        <v-list-item-title>Cancelar Manifesto</v-list-item-title>
                      </v-list-item>
                      <v-list-item :key="props.item._id + 50" @click="abrirDialogCondutor(props.item)" v-show="props.item.status === constantes.STATUS_AUTORIZADA">
                        <v-list-item-icon><v-icon>airline_seat_recline_normal</v-icon></v-list-item-icon>
                        <v-list-item-title>Adicionar Condutor</v-list-item-title>
                      </v-list-item>
                      <v-list-item :key="props.item._id + 60" @click="encerrarManifesto(props.item)" v-show="props.item.status === constantes.STATUS_AUTORIZADA">
                        <v-list-item-icon><v-icon>flag</v-icon></v-list-item-icon>
                        <v-list-item-title>Encerrar Manifesto</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <tr>
                <td :colspan="headers.length" class="px-0">
                  <v-card flat>
                    <v-card-title v-if="item.status === constantes.STATUS_AUTORIZADA">Informações Adicionais</v-card-title>
                    <v-card-title v-else-if="item.status === constantes.STATUS_CANCELADA">Eventos</v-card-title>

                    <v-card-text v-if="item.status === constantes.STATUS_AUTORIZADA || item.status === constantes.STATUS_ENCERRADO">
                      <v-row dense>
                        <v-col cols="12" sm="6">
                          <v-text-field outlined label="Chave de Acesso" :value="item.chave | formatarChave" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-text-field outlined label="Protocolo" :value="item.autorizacao.protocolo" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-text-field outlined label="Data da Autorização" :value="item.autorizacao.data | formatarDataHora" readonly></v-text-field>
                        </v-col>
                      </v-row>

                      <v-container fluid v-if="item.eventos && item.eventos.length > 0">
                        <v-data-iterator :items="item.eventos" :options.sync="options" hide-default-footer>
                          <template v-slot:default="props">
                            <v-row dense>
                              <v-col v-for="evento in props.items" :key="evento._id" sm="4">
                                <v-card flat>
                                  <v-list dense>
                                    <v-list-item>
                                      <v-list-item-icon>
                                        <v-icon v-if="evento.tipo == '110111'">cancel</v-icon>
                                        <v-icon v-else-if="evento.tipo == '110114'">airline_seat_recline_normal</v-icon>
                                        <v-icon v-else-if="evento.tipo == '110112'">flag</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>{{ definirNomeEvento(evento.tipo) }}</v-list-item-title>
                                        <v-list-item-subtitle>Protocolo: {{ evento.protocolo }}</v-list-item-subtitle>
                                        <v-list-item-subtitle>Data: {{ evento.data | formatarData }}</v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-card>
                              </v-col>
                            </v-row>
                          </template>
                        </v-data-iterator>
                      </v-container>
                    </v-card-text>

                    <v-card-text v-if="item.status === constantes.STATUS_CANCELADA">
                      <v-container fluid v-if="item.eventos && item.eventos.length > 0">
                        <v-data-iterator :items="item.eventos" :options.sync="options" hide-default-footer>
                          <template v-slot:default="props">
                            <v-row dense>
                              <v-col v-for="evento in props.items" :key="evento._id" sm="4">
                                <v-card flat>
                                  <v-list dense>
                                    <v-list-item>
                                      <v-list-item-icon>
                                        <v-icon v-if="evento.tipo == '110111'">cancel</v-icon>
                                        <v-icon v-else-if="evento.tipo == '110114'">airline_seat_recline_normal</v-icon>
                                        <v-icon v-else-if="evento.tipo == '110112'">flag</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>{{ definirNomeEvento(evento.tipo) }}</v-list-item-title>
                                        <v-list-item-subtitle>Protocolo: {{ evento.protocolo }}</v-list-item-subtitle>
                                        <v-list-item-subtitle>Data: {{ evento.data | formatarData }}</v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-card>
                              </v-col>
                            </v-row>
                          </template>
                        </v-data-iterator>
                      </v-container>
                    </v-card-text>

                    <v-card-text v-if="item.status === constantes.STATUS_ERRO">
                      <v-row dense>
                        <v-col cols="12" sm="2">
                          <v-text-field outlined label="Código" v-model="item.retorno_sefaz.status" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="10">
                          <v-text-field outlined label="Descrição do Erro" v-model="item.retorno_sefaz.motivo" readonly></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-widget>
    </v-container>

    <dialog-adicionar-condutor :dialog="mostrarDialogAdicionarCondutor" :manifesto="manifestoSelecionado" @fechar="mostrarDialogAdicionarCondutor = false" @adicionarCondutor="enviarAdicaoCondutor"></dialog-adicionar-condutor>
  </div>
</template>

<script>
import DialogAdicionarCondutor from './DialogAdicionarCondutor';
import { mapState, mapActions } from 'vuex';
import constantes from '@/util/constantes';

export default {
  components: {
    DialogAdicionarCondutor,
  },

  data() {
    return {
      headers: [
        { text: 'Número', value: 'numero', sortable: false },
        { text: 'Série', value: 'serie', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Data', value: 'data_emissao', sortable: false },
        { text: 'Placa', value: 'placa', sortable: false },
        { text: 'UF', value: 'uf', sortable: false },
        { text: 'Valor', value: 'valor_carga', sortable: false },
        { text: 'Ações', value: 'name', align: 'center', sortable: false },
      ],
      mostrarDialogAdicionarCondutor: false,
      manifestoSelecionado: {},
      constantes: constantes,
      busca: '',
      options: {
        itemsPerPage: 10,
        page: 1,
      },
    };
  },

  watch: {
    options: {
      handler() {
        this.carregarManifestos();
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('manifesto', {
      manifestos: 'manifestos',
      carregando: 'carregando',
      total: 'totalManifestos',
    }),

    ...mapState({
      carregandoStatus: 'carregando',
      usuario: 'usuario',
      certificadoVencido: 'certificadoVencido',
      statusSefaz: 'statusSefazMDFe',
    }),

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    ...mapActions('manifesto', {
      reprocessar: 'reprocessarManifesto',
    }),

    consultarStatusServicoMDFe() {
      this.$store.dispatch('sefaz/consultarStatusServico', true, 'MDFe');
    },

    async cancelarManifesto(manifesto) {
      try {
        await this.$store.dispatch('manifesto/cancelarManifesto', manifesto);
      } catch (error) {
        this.$store.commit('setMensagemErro', error);
      }
    },

    async encerrarManifesto(manifesto) {
      try {
        await this.$store.dispatch('manifesto/encerrarManifesto', manifesto);
      } catch (error) {
        this.$store.commit('setMensagemErro', error);
      }
    },

    editarManifesto(manifesto) {
      this.$store.commit('manifesto/editar', manifesto);
      this.$router.push('/manifestos/editar');
    },

    visualizar(manifesto) {
      this.$store.commit('manifesto/visualizar', manifesto);
      this.$router.push('/manifestos/damdfe');
    },

    novoManifesto() {
      this.$store.commit('manifesto/novo');
      this.$router.push('/manifestos/novo');
    },

    carregarManifestos() {
      this.$store.dispatch('manifesto/carregarManifestos', this.options);
    },

    limparBusca() {
      this.carregarManifestos();
    },

    filtrarManifestos(event) {
      const newOptions = {
        ...this.options,
        query: this.busca,
      };

      this.$store.dispatch('manifesto/carregarManifestos', newOptions);
      setTimeout(() => {
        event.target.select();
      }, 300);
    },

    abrirDialogCondutor(manifesto) {
      this.manifestoSelecionado = manifesto;
      this.mostrarDialogAdicionarCondutor = true;
    },

    enviarAdicaoCondutor(condutor) {
      this.mostrarDialogAdicionarCondutor = false;
      this.$store.dispatch('manifesto/adicionarCondutor', condutor);
    },

    definirCor(status) {
      if (status) {
        if (status == constantes.STATUS_AUTORIZADA) {
          return 'green';
        } else if (status == constantes.STATUS_PROCESSAMENTO) {
          return 'orange';
        } else if (status == constantes.STATUS_ENCERRADO) {
          return 'navy blue';
        } else if (status == constantes.STATUS_CANCELADA) {
          return 'purple';
        } else if (status == constantes.STATUS_ERRO) {
          return 'red';
        } else if (status == constantes.STATUS_CADASTRADA) {
          return 'secondary';
        }
      } else {
        return 'pink';
      }
    },

    definirLetra(status) {
      if (status) {
        return status.charAt(0);
      } else {
        return '';
      }
    },

    definirNomeEvento(codigo) {
      if (codigo == '110111') {
        return 'Cancelamento de MDF-e';
      } else if (codigo == '110112') {
        return 'Encerramento';
      } else if (codigo == '110114') {
        return 'Inclusão de Condutor';
      }
    },
  },
};
</script>
